<div>Аккаунт успешно создан</div>
<div *ngIf="confirmationForm.controls.email.value.length > 0">
  Для подтверждения адреса электронной почты вам направлена ссылка на {{confirmationForm.controls.email.value}}
</div>
<div *ngIf="maskedPhone.length > 0">
  Для подтверждения телефона вам направлен код на {{maskedPhone}}
  <form [formGroup]="confirmationForm">
    <input formControlName="phone" hidden>
    <input formControlName="email" hidden>
    <input type="text"
           formControlName="code"
           pInputText
           placeholder="ПРОВЕРОЧНЫЙ КОД">
  </form>
  <button (click)="resend()"
          [disabled]="!canResend"
  >
    <span *ngIf="canResend">Переотправить код</span>
    <span *ngIf="!canResend">Переотправить код через {{ timer | async | date:'mm:ss' }}</span>
  </button>
</div>
<input *ngIf="maskedPhone.length > 0"
       [disabled]="!confirmationForm.valid"
       class="submit"
       type="submit"
       value="Подтвердить"
       (click)="confirm()">
<input value="Вернуться на страницу входа" (click)="toSignin()">
