import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {EMPTY, Observable, of} from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { CheckCodeForm } from '../../login-screen/reset/reset.component';
import { AuthService } from '../../service/auth.service';
import { Router } from '@angular/router';
import {
	AuthActionTypes,
	loginSuccess,
	resetFailure, resetGetCodeSuccess, resetSuccess, checkSuccess
} from '../actions/auth.actions';
import { openMessage } from '../actions/message.actions';
import {SigninForm} from "../../login-screen/log-in/log-in.component";
import {Store} from "@ngrx/store";
import {AppState} from "../states/app.states";
import {SignupForm} from "../../login-screen/sign-up/sign-up.component";
import {ConfirmationForm, ResendForm} from "../../login-screen/confirmation/confirmation.component";

@Injectable()
export class AuthEffects {

	constructor(private actions$: Actions,
							private sessionService: AuthService,
							private router: Router,
							private store: Store<AppState>) {}

	updateToken(token: string) {
		localStorage.setItem('accessToken', token);
		const exp = JSON.parse(atob(token.split('.')[1])).exp * 1000;
		const refreshTime = exp - Date.now() - 5 * 60 * 1000;
		localStorage.setItem('expiration', refreshTime.toString());
		setTimeout(() => this.store.dispatch({ type: AuthActionTypes.CHECK }), 10000);
	}

	check: Observable<any> = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActionTypes.CHECK),
			switchMap(() => {
				const expiration = localStorage.getItem("expiration");
				if (!expiration) {
					localStorage.removeItem('accessToken');
					return EMPTY;
				}

				const exp = Number.parseInt(expiration);
				if (exp < Date.now()) {
					return this.sessionService.token().pipe(
						map(data => checkSuccess(data)),
						catchError(() => {
							localStorage.removeItem('accessToken');
							return EMPTY;
						})
					);
				}

				const accessToken = localStorage.getItem("accessToken") || '';
				return of(checkSuccess({ token: accessToken }));
			})
		)
	);

	signup: Observable<any> = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActionTypes.SIGNUP),
			switchMap((payload: SignupForm) =>
				this.sessionService.signup(payload).pipe(
					tap(data => {
						localStorage.setItem('email', payload.email);
						localStorage.setItem('phone', payload.phone);
						if (payload.phone.length > 0)
							localStorage.setItem('codeSent', Date.now().toString());
						this.router.navigateByUrl("/confirmation");
					})
				)
			)
		), { dispatch: false }
	);

	login: Observable<any> = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActionTypes.LOGIN),
			switchMap((payload: SigninForm) =>
				this.sessionService.login(payload).pipe(
					map((data) => loginSuccess(data)),
					tap(data => {
						localStorage.setItem('email', payload.emailOrPhone);
						this.updateToken(data.token);
						this.router.navigateByUrl('/core');
					})
				)
			)
		), { dispatch: false }
	);

	logout: Observable<any> = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActionTypes.LOGOUT),
			switchMap(() =>
				this.sessionService.logout().pipe(
					tap(() => {
						localStorage.removeItem('idToken');
						this.router.navigateByUrl('/login');
					}),
					catchError(() => EMPTY)
				)
			)
		), { dispatch: false }
	);

	checkSuccess: Observable<any> = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActionTypes.CHECK_SUCCESS),
			tap((data: any) => {
				this.updateToken(data.token);
				this.router.navigateByUrl('/core');
			})
		), { dispatch: false }
	);

	loginFailure: Observable<any> = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActionTypes.LOGIN_FAILURE),
			switchMap((payload) => {
				localStorage.removeItem('idToken');
				this.router.navigateByUrl('/login');
				return of(openMessage(payload));
			})
		),
	);

	signupFailure: Observable<any> = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActionTypes.SIGNUP_FAILURE),
			switchMap((payload: any)  => {
				if (payload.err.error.error.message.indexOf('already exist') > -1 ){
					this.router.navigateByUrl('/login');
				}
				return of(openMessage(payload.err));
			})
		),
	);

	confirm: Observable<any> = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActionTypes.CONFIRM),
			switchMap((payload: ConfirmationForm) => {
				return this.sessionService.confirm(payload).pipe(
					tap((data: any) => {
						this.router.navigateByUrl("/login");
					}),
				);
			})
		), { dispatch: false });

	resend: Observable<any> = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActionTypes.RESEND),
			switchMap((payload: ResendForm) => {
				return this.sessionService.resend(payload).pipe(
					tap((data: any) => {
						// this.router.navigateByUrl("/confirmation");
					}),
				);
			})));

	reset: Observable<any> = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActionTypes.RESET),
			switchMap((payload: CheckCodeForm) => {
				if (payload.userId == null){
					return this.sessionService.get_code(payload).pipe(
						map((data) => resetGetCodeSuccess({ id: data.userId})),
						catchError((err) => {
							return of(resetFailure({err, phone: payload.phone || ''}));
						})
					);
				} else{
					return this.sessionService.check_code(payload).pipe(
						map((data) => resetSuccess(data)),
						catchError((err) => {
							return of(resetFailure({err, phone: payload.phone || ''}));
						})
					);
				}
			})));

	resetGetCodeSuccess: Observable<any> = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActionTypes.RESET_GET_CODE_SUCCESS),
			switchMap((payload: any)  => {
				const data: any = {error: {errorMessage: 'На ваш телефон выслан код'}};
				return of(openMessage(data));
			})
		),
	);

	resetSuccess: Observable<any> = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActionTypes.RESET_SUCCESS),
			switchMap((payload) => {
				this.router.navigateByUrl('/login');
				const data: any = {error: {errorMessage: 'Пароль успешно установлен'}};
				return of(openMessage(data));
			})
		),
	);

	resetFailure: Observable<any> = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthActionTypes.RESET_FAILURE),
			switchMap((payload: any)  => {
				return of(openMessage(payload.err));
			})
		),
	);


}
