import {Component, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {confirm, resend} from '../../store/actions/auth.actions';
import { AppState } from '../../store/states/app.states';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Observable, Subscription, takeWhile, timer} from "rxjs";
import {map} from "rxjs/operators";

export interface IConfirmationForm {
  email: FormControl<string>
  phone: FormControl<string>
  code: FormControl<string>
}

export interface ConfirmationForm{
  phone: string
  code: string
}

export interface ResendForm{
  phone: string
}

@Component({
  selector: 'confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})

export class ConfirmationComponent implements OnInit, OnDestroy{
  confirmationForm: FormGroup<IConfirmationForm> = new FormGroup<IConfirmationForm>({
    email: new FormControl<string>('', {nonNullable: true}),
    code: new FormControl<string>('', {validators: [Validators.minLength(6)], nonNullable: true}),
    phone: new FormControl<string>('', {nonNullable: true}),
  });

  maskedPhone: string = '';
  canResend: boolean = false;
  timer: Observable<number> = new Observable();
  timerSubscription?: Subscription;

  constructor(private store: Store<AppState>,
              private activatedRoute: ActivatedRoute,
              private router: Router) {}

  ngOnInit(): void {
    this.confirmationForm.controls.email.setValue(localStorage.getItem("email") || '');
    this.confirmationForm.controls.phone.setValue(localStorage.getItem("phone") || '');
    this.maskedPhone = this.confirmationForm.controls.phone.value.replace(/\d(?=\d{4})/g, '*');
    this.setCanResend();
  }

  ngOnDestroy() {
    this.timerSubscription?.unsubscribe();
  }

  confirm(): void {
    if (this.confirmationForm.valid){
      this.store.dispatch(confirm(this.confirmationForm.getRawValue()));
    }
  }

  resend() {
    this.store.dispatch(resend({ phone: this.confirmationForm.controls.phone.value}));
    localStorage.setItem("codeSent", Date.now().toString());
    this.setCanResend();
  }

  setCanResend() {
    let date = localStorage.getItem("codeSent");
    this.canResend = !date || Number.parseInt(date) + 300000 < Date.now();
    this.startTimer();
  }

  private startTimer() {
    this.timer = timer(0, 1000).pipe(
      map(n => (5 * 60 - n) * 1000),
      takeWhile(n => n >= 0),
    );
    this.timerSubscription = this.timer.subscribe(value => {
      if (value === 0){
        this.canResend = true;
        this.timerSubscription?.unsubscribe();
      }
    });
  }

  toSignin() {
    this.router.navigateByUrl("/login");
  }
}
