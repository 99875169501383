import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, selectUser } from '../../store/states/app.states';
import { login } from '../../store/actions/auth.actions';
import {FormControl, FormGroup, Validators} from "@angular/forms";

export interface ISigninForm {
  emailOrPhone: FormControl<string>,
  password: FormControl<string>
}

export interface SigninForm {
  emailOrPhone: string,
  password: string;
}

@Component({
  selector: 'app-log-in',
  templateUrl: './log-in.component.html',
  styleUrls: ['./log-in.component.scss']
})

export class LogInComponent implements OnInit {
  signinForm: FormGroup<ISigninForm> = new FormGroup<ISigninForm>({
    emailOrPhone: new FormControl<string>('', {validators: [Validators.pattern(/(^[^@\s]+@[^@\s]+\.[^@\s]+$)|(^7\d{10}$)/), Validators.required], nonNullable: true}),
    password: new FormControl<string>('', {validators: [Validators.required], nonNullable: true})
  }, );

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.store.select(selectUser).subscribe((state) => {
      setTimeout(() => {
        // if (state && (state as User).phones) {
        //   this.signinForm.controls.email.setValue((state as User).email[0].data);
        // } else{
          this.signinForm.controls.emailOrPhone.setValue(localStorage.getItem('email') || '');
        // }
      }, 10);
    });
    // const cuStr = localStorage.getItem('currentUser');
    // if (cuStr) {
    //     const cu = JSON.parse(cuStr);
    //     this.signinForm.phone = cu.phone;
    // } else{
    //
    // }
    // this.checkSession();
  }

  login(): void {
    this.store.dispatch(login(this.signinForm.getRawValue()));
    // localStorage.setItem('currentUser', JSON.stringify({phone: this.signinForm.phone}));
    /*this.sessionService.login(this.signinForm).subscribe(result => {
        if (result == "OK")
            this._userService.cacheUserAndOrg();
    });*/
  }

  checkKeyPress(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.login();
    }
  }

}
