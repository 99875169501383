<form [formGroup]="signinForm">
  <input formControlName="emailOrPhone" placeholder="E-MAIL или Телефон">
  <input formControlName="password" placeholder="Пароль" type="password">
</form>

<div *ngIf="signinForm.controls.emailOrPhone.touched && signinForm.controls.emailOrPhone.invalid">
  <small *ngIf="signinForm.controls.emailOrPhone.errors?.['required']">Не заполнен Email или телефон</small>
  <small *ngIf="signinForm.controls.emailOrPhone.errors?.['pattern']">Неверный формат email/телефона</small>
</div>

<div *ngIf="signinForm.controls.password.touched && signinForm.controls.password.invalid">
  <small *ngIf="signinForm.controls.password.errors?.['required']">Пароль обязателен</small>
</div>

<div class="link_button" routerLink="/reset">Забыли пароль?</div>
<input class="submit" type="submit" value="ВОЙТИ В СИСТЕМУ" (click)="login()"
       style="margin-top: 35px">
