import { createAction, props } from '@ngrx/store';
import { SigninForm } from '../../login-screen/log-in/log-in.component';
import { CheckCodeForm } from '../../login-screen/reset/reset.component';
import { SignupForm} from '../../login-screen/sign-up/sign-up.component';
import {TokenResponse} from "../../service/auth.service";
import {ConfirmationForm, ResendForm} from "../../login-screen/confirmation/confirmation.component";

export enum AuthActionTypes {
    CHECK = '[Auth] Check',
    CHECK_SUCCESS = '[Auth] Check Success',
    LOGIN = '[Auth] Login',
    LOGIN_SUCCESS = '[Auth] Login Success',
    LOGIN_FAILURE = '[Auth] Login FAIL',
    SIGNUP = '[Auth] Signup',
    SIGNUP_FAILURE = '[Auth] SIGNUP FAIL',
    CONFIRM = '[Auth] Confirm',
    RESEND = '[Auth] Resend code',
    RESET = '[Auth] Reset',
    RESET_GET_CODE_SUCCESS = '[Auth] RESET Get code Success',
    RESET_SUCCESS = '[Auth] RESET Success',
    RESET_FAILURE = '[Auth] RESET FAIL',
    LOGOUT = '[Auth] LOGOUT',
}

export const check = createAction(
    AuthActionTypes.CHECK
);

export const checkSuccess = createAction(
    AuthActionTypes.CHECK_SUCCESS,
    props<TokenResponse>()
);

export const login = createAction(
    AuthActionTypes.LOGIN,
    props<SigninForm>()
);

export const logout = createAction(
    AuthActionTypes.LOGOUT
);

export const loginSuccess = createAction(
    AuthActionTypes.LOGIN_SUCCESS,
    props<{ token: any }>()
);

export const loginFailure = createAction(
    AuthActionTypes.LOGIN_FAILURE,
    props<{ data: any }>()
);

export const signup = createAction(
    AuthActionTypes.SIGNUP,
    props<SignupForm>()
);

export const signupFailure = createAction(
    AuthActionTypes.SIGNUP_FAILURE,
    props<{ data: any }>()
);

export const confirm = createAction(
  AuthActionTypes.CONFIRM,
  props<ConfirmationForm>()
);

export const resend = createAction(
  AuthActionTypes.RESEND,
  props<ResendForm>()
);

export const reset = createAction(
    AuthActionTypes.RESET,
    props<CheckCodeForm>()
);

export const resetGetCodeSuccess = createAction(
    AuthActionTypes.RESET_GET_CODE_SUCCESS,
    props<{ id: any }>()
);

export const resetSuccess = createAction(
    AuthActionTypes.RESET_SUCCESS,
    props<{ data: any }>()
);

export const resetFailure = createAction(
    AuthActionTypes.RESET_FAILURE,
    props<{ err: any, phone: string }>()
);
